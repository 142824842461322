import { Grid } from 'components/grid';
import { useImages } from 'hooks';
import React from 'react';
import InView, { useInView } from 'react-intersection-observer';
import { aboutheaderStyles } from './AboutHeader.styles';
import { motion } from 'framer-motion';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';

export const AboutHeader: React.FC = () => {
  const { ref } = useInView();
  const { dakovackiVezovi, kopackiRit, erdut } = useImages();
  const { formatMessage } = useIntl();
  const kopackiRitData = getImage(kopackiRit);
  const erdutData = getImage(erdut);
  const vezoviData = getImage(dakovackiVezovi);

  return (
    <div ref={ref} css={aboutheaderStyles.wrapper}>
      <div css={aboutheaderStyles.imageWrapper}>
        {kopackiRitData && (
          <GatsbyImage
            css={aboutheaderStyles.headerImage}
            image={kopackiRitData}
            alt={formatMessage({
              id: 'images.titles.kopacki-rit',
            })}
          />
        )}
        <div css={aboutheaderStyles.overlay} />
      </div>
      <Grid type="page">
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.8 }}
              css={aboutheaderStyles.imageSpecial}
            >
              {vezoviData && (
                <GatsbyImage
                  image={vezoviData}
                  alt={formatMessage({
                    id: 'images.titles.dakovacki-vezovi',
                  })}
                  css={aboutheaderStyles.imagePrimary}
                />
              )}
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.8, delay: 0.4 }}
              css={[
                aboutheaderStyles.summaryWrapper,
                aboutheaderStyles.summaryWrapperPrimary,
              ]}
            >
              <p css={aboutheaderStyles.gap}>
                <FormattedMessage id="about-us.header.paragraph-one" />
              </p>
              <p>
                <FormattedMessage id="about-us.header.paragraph-two" />
              </p>
              <p css={aboutheaderStyles.summaryParagraphPrimary}>
                <FormattedMessage id="about-us.header.paragraph-three" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.8 }}
              css={[
                aboutheaderStyles.summaryWrapper,
                aboutheaderStyles.summaryWrapperSecondary,
              ]}
            >
              <p>
                <FormattedMessage id="about-us.header.paragraph-three" />
              </p>
            </motion.div>
          )}
        </InView>
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: '40px' }}
              animate={{ opacity: inView ? 1 : 0, y: inView ? 0 : '40px' }}
              transition={{ ease: 'circOut', duration: 0.8 }}
              css={aboutheaderStyles.contentWrapper(2, 10)}
            >
              {erdutData && (
                <GatsbyImage
                  image={erdutData}
                  alt={formatMessage({
                    id: 'images.titles.erdut',
                  })}
                />
              )}
            </motion.div>
          )}
        </InView>
      </Grid>
    </div>
  );
};
