import { css } from '@emotion/react';
import { colors, breakpoints } from 'style';

const wrapper = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: calc(var(--unit) * 22);

  font-family: var(--font-family-primary);
  font-weight: var(--font-weight-regular);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 40);
  }
`;

const imageWrapper = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 80vh;
  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 22);
  }
`;

const headerImage = css`
  position: absolute;
  width: 100%;
  height: 100%;

  object-fit: cover;

  z-index: -2;
`;

const overlay = css`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  background-color: ${colors.black};
  opacity: 0.3;
`;

const title = css`
  max-width: calc(var(--unit) * 80);

  font-size: var(--font-size-xxLarge);
  line-height: var(--line-height-xxxLarge);
  text-align: center;
  font-weight: var(--font-weight-regular);

  color: ${colors.white};

  @media ${breakpoints.medium} {
    max-width: calc(var(--unit) * 160);

    font-size: var(--font-size-huge);
    line-height: var(--line-height-huge);
  }
`;

const description = css`
  grid-column: 2 / span 8;
  font-size: var(--font-size-medium);
  line-height: var(--line-height-large);
  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxLarge);
    line-height: var(--line-height-xxxLarge);
    letter-spacing: 1px;
    margin-bottom: calc(var(--unit) * 40);
  }
`;

const summaryWrapper = css`
  font-family: var(--font-family-secondary);
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);
  color: ${colors.textLight};
`;

const summaryWrapperPrimary = css`
  grid-column: 7 / span 5;

  @media ${breakpoints.large} {
    margin-bottom: calc(var(--unit) * 20);
  }
`;

const summaryWrapperSecondary = css`
  grid-column: 2 / span 10;

  margin-bottom: calc(var(--unit) * 12);

  @media ${breakpoints.medium} {
    margin: calc(var(--unit) * 10) 0 calc(var(--unit) * 10);
  }

  @media ${breakpoints.large} {
    display: none;
  }
`;

const summaryParagraphPrimary = css`
  display: none;

  @media ${breakpoints.large} {
    display: inline;
    margin-bottom: calc(var(--unit) * 40);
  }
`;

const gap = css`
  margin-bottom: var(--spacing-huge);
`;

const imageSpecial = css`
  display: none;
  grid-column: 2 / span 4;
  max-height: calc(var(--unit) * 125);
  object-fit: cover;

  @media ${breakpoints.medium} {
    display: block;
  }
`;

const imagePrimary = css`
  width: 100%;
  height: 100%;
`;

const contentWrapper = (colStart: number, colSpan: number) => css`
  @media ${breakpoints.medium} {
    grid-column: ${colStart} / span ${colSpan};
  }
`;

export const aboutheaderStyles = {
  title,
  wrapper,
  imageWrapper,
  headerImage,
  overlay,
  description,
  contentWrapper,
  summaryWrapper,
  summaryWrapperPrimary,
  summaryWrapperSecondary,
  summaryParagraphPrimary,
  gap,
  imageSpecial,
  imagePrimary,
};
