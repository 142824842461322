import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';
import React from 'react';
import { servicesCardStyles } from './ServicesCard.styles';

interface Props {
  imageUrl: IGatsbyImageData;
  imageAlt: string;
  cardNumber: string;
  title: string;
}

export const ServicesCard: React.FC<Props> = ({
  imageUrl,
  imageAlt,
  cardNumber,
  title,
}) => {
  const imageData = getImage(imageUrl);

  return (
    <div css={servicesCardStyles.wrapper}>
      {imageData && (
        <GatsbyImage
          loading="lazy"
          css={servicesCardStyles.image}
          image={imageData}
          alt={imageAlt}
        />
      )}
      <p css={servicesCardStyles.cardNumber}>{cardNumber}</p>
      <p css={servicesCardStyles.title}>{title}</p>
    </div>
  );
};
