import { css } from '@emotion/react';
import { breakpoints, colors } from 'style';

const wrapper = css`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: var(--font-family-secondary);
  font-weight: var(--font-weight-regular);

  margin-bottom: calc(var(--unit) * 16);

  @media ${breakpoints.medium} {
    margin-bottom: 0;
  }
`;

const image = css`
  width: 100%;
  margin-bottom: var(--spacing-xxlrg);
  min-height: calc(var(--unit) * 100);
  object-fit: cover;

  @media ${breakpoints.medium} {
    min-height: calc(var(--unit) * 90);
  }

  @media ${breakpoints.large} {
    margin-bottom: var(--spacing-huge);
    min-height: calc(var(--unit) * 140);
  }
`;

const cardNumber = css`
  font-size: var(--font-size-base);
  line-height: var(--line-height-base);

  margin-bottom: var(--spacing-lrg);
  color: ${colors.textLight};
`;

const title = css`
  font-size: var(--font-size-large);
  line-height: var(--line-height-xLarge);
  font-weight: var(--font-weight-regular);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-medium);
    line-height: var(--line-height-large);
  }

  @media ${breakpoints.large} {
    font-size: var(--font-size-large);
    line-height: var(--line-height-xLarge);
  }
`;

export const servicesCardStyles = {
  title,
  wrapper,
  image,
  cardNumber,
};
