import { Layout } from 'components/layout';
import { AboutUsPage } from 'modules/about-us';
import React from 'react';

const AboutProject: React.FC = () => (
  <Layout isGhostNav={true}>
    <AboutUsPage />
  </Layout>
);

export default AboutProject;
