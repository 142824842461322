import { SeoTags } from 'components';
import { getSrc } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useImages } from 'hooks';
import React from 'react';
import { Adventures } from '../adventures';
import { AboutHeader } from '../header';
import { Services } from '../services';

export const AboutUsPage: React.FC = () => {
  const { kopackiRit } = useImages();
  const { formatMessage } = useIntl();

  return (
    <>
      <SeoTags
        title={formatMessage({ id: 'about-us.seo.title' })}
        description={formatMessage({ id: 'about-us.seo.description' })}
        imagePath={getSrc(kopackiRit)}
      />
      <AboutHeader />
      <Services />
      <Adventures />
    </>
  );
};
