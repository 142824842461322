import { Grid } from 'components';
import {} from 'gatsby-plugin-image';
import { useImages } from 'hooks';
import React from 'react';
import { servicesStyles } from './Services.styles';
import { ServicesCard } from './ServicesCard';
import { motion } from 'framer-motion';
import { FormattedMessage, useIntl } from 'gatsby-plugin-react-intl';
import InView from 'react-intersection-observer';

export const Services: React.FC = () => {
  const { formatMessage } = useIntl();
  const {
    startUp,
    evaluacija,
    turistickaAgencija,
    dravaDunav,
    panonaFest2,
    vodenaVrata,
  } = useImages();

  return (
    <section>
      <Grid css={servicesStyles.root} type="page">
        <InView threshold={0.4} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.h1
              ref={ref}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'circOut', duration: 0.4 }}
              css={servicesStyles.title}
            >
              <FormattedMessage id="about-us.services.title" />
            </motion.h1>
          )}
        </InView>
        <InView threshold={0.3} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeOut', duration: 0.6, delay: 0.2 }}
              css={[
                servicesStyles.contentWrapper(2, 4),
                servicesStyles.gapHuge,
              ]}
            >
              <ServicesCard
                imageAlt={formatMessage({
                  id: 'about-us.services.service-card-startup.image-alt',
                })}
                imageUrl={startUp}
                title={formatMessage({
                  id: 'about-us.services.service-card-startup.title',
                })}
                cardNumber="/ 01"
              />
            </motion.div>
          )}
        </InView>
        <InView threshold={0.3} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeOut', duration: 0.6, delay: 0.2 }}
              css={[servicesStyles.contentWrapper(7, 4), servicesStyles.gap]}
            >
              <ServicesCard
                imageAlt={formatMessage({
                  id: 'about-us.services.service-card-system.image-alt',
                })}
                imageUrl={evaluacija}
                title={formatMessage({
                  id: 'about-us.services.service-card-system.title',
                })}
                cardNumber="/ 02"
              />
            </motion.div>
          )}
        </InView>
        <InView threshold={0.3} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeOut', duration: 0.6, delay: 0.2 }}
              css={[
                servicesStyles.contentWrapper(2, 4),
                servicesStyles.gapMedium,
              ]}
            >
              <ServicesCard
                imageAlt={formatMessage({
                  id: 'about-us.services.service-card-agency.image-alt',
                })}
                imageUrl={turistickaAgencija}
                title={formatMessage({
                  id: 'about-us.services.service-card-agency.title',
                })}
                cardNumber="/ 03"
              />
            </motion.div>
          )}
        </InView>
        <InView threshold={0.3} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeOut', duration: 0.6, delay: 0.2 }}
              css={[
                servicesStyles.contentWrapper(7, 4),
                servicesStyles.gapLarge,
              ]}
            >
              <ServicesCard
                imageAlt={formatMessage({
                  id: 'about-us.services.service-card-platform.image-alt',
                })}
                imageUrl={dravaDunav}
                title={formatMessage({
                  id: 'about-us.services.service-card-platform.title',
                })}
                cardNumber="/ 04"
              />
            </motion.div>
          )}
        </InView>
        <InView threshold={0.3} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeOut', duration: 0.6, delay: 0.2 }}
              css={[
                servicesStyles.contentWrapper(2, 4),
                servicesStyles.gapHuge,
              ]}
            >
              <ServicesCard
                imageAlt={formatMessage({
                  id: 'about-us.services.service-card-festivals.image-alt',
                })}
                imageUrl={panonaFest2}
                title={formatMessage({
                  id: 'about-us.services.service-card-festivals.title',
                })}
                cardNumber="/ 05"
              />
            </motion.div>
          )}
        </InView>
        <InView threshold={0.3} triggerOnce={true}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '40px' }}
              transition={{ ease: 'easeOut', duration: 0.6, delay: 0.2 }}
              css={[
                servicesStyles.contentWrapper(7, 4),
                servicesStyles.gapLarge,
              ]}
            >
              <ServicesCard
                imageAlt={formatMessage({
                  id: 'about-us.services.service-card-promotion.image-alt',
                })}
                imageUrl={vodenaVrata}
                title={formatMessage({
                  id: 'about-us.services.service-card-promotion.title',
                })}
                cardNumber="/ 06"
              />
            </motion.div>
          )}
        </InView>
      </Grid>
    </section>
  );
};
