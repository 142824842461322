import { css } from '@emotion/react';
import { breakpoints } from 'style';

const root = css`
  font-weight: 400;
  margin-bottom: calc(var(--unit) * 22);

  @media ${breakpoints.medium} {
    margin-bottom: calc(var(--unit) * 40);
  }
`;

const title = css`
  grid-column: 2 / span 6;

  font-family: var(--font-family-pimary);
  font-size: var(--font-size-xLarge);
  font-weight: var(--font-weight-regular);
  line-height: var(--line-height-xxLarge);
  margin-bottom: var(--spacing-huge);

  @media ${breakpoints.medium} {
    font-size: var(--font-size-xxxLarge);
    line-height: calc(var(--unit) * 14);
    margin-bottom: calc(var(--unit) * 10);
  }
`;

const contentWrapper = (colStart: number, colSpan: number) => css`
  @media ${breakpoints.medium} {
    grid-column: ${colStart} / span ${colSpan};
  }
`;

const gap = css`
  @media ${breakpoints.medium} {
    margin-top: calc(var(--unit) * 22);
  }
`;

const gapMedium = css`
  @media ${breakpoints.medium} {
    margin-top: calc(var(--unit) * 10);
  }
`;

const gapLarge = css`
  @media ${breakpoints.medium} {
    margin-top: calc(var(--unit) * 50);
  }
`;

const gapHuge = css`
  @media ${breakpoints.medium} {
    margin-bottom: calc(0px - var(--unit) * 36);
  }
`;

export const servicesStyles = {
  root,
  title,
  contentWrapper,
  gap,
  gapMedium,
  gapHuge,
  gapLarge,
};
