import { Gallery, Grid, SliderComponent } from 'components';
import React, { useEffect, useState } from 'react';
import { adventuresStyles } from './Adventures.styles';
import CloseSvg from 'assets/icons/close.svg';
import { useImages } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { GatsbyImage, getImage, getSrc } from 'gatsby-plugin-image';
import { FormattedMessage } from 'gatsby-plugin-react-intl';

export const Adventures: React.FC = () => {
  const { galleryContent } = useImages();
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.3 });
  const [isShowClicked, setIsShowClicked] = useState(false);
  const [indexOfImage, setIndexOfImage] = useState(0);
  const numberOfItems = galleryContent.length;

  function handleEsc(e: KeyboardEvent) {
    if (e.keyCode === 27) {
      setIsShowClicked(false);
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);

  return (
    <>
      <AnimatePresence>
        {isShowClicked && (
          <div
            css={adventuresStyles.galleryWrapper}
            onClick={() => setIsShowClicked(false)}
          >
            <Gallery
              images={galleryContent.map(({ imageTitle, imageUrl }) => {
                const imageSrc = getSrc(imageUrl);
                return {
                  imageSrc,
                  originalAlt: imageTitle,
                };
              })}
              indexOfImage={indexOfImage}
            />
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              css={[
                adventuresStyles.button,
                adventuresStyles.galleryButtonClose,
              ]}
              onClick={() => setIsShowClicked(false)}
            >
              <CloseSvg />
            </motion.button>
          </div>
        )}
      </AnimatePresence>
      <section ref={ref}>
        <Grid css={adventuresStyles.root} type="page">
          <motion.h2
            animate={{ opacity: inView ? 1 : 0, y: inView ? '0' : '20px' }}
            transition={{ ease: 'circOut', duration: 0.4 }}
            css={adventuresStyles.title}
          >
            <FormattedMessage id="about-us.adventures.title" />
          </motion.h2>
          <div css={adventuresStyles.sliderWrapper}>
            <SliderComponent
              itemsPerSlide={3}
              numberOfItems={numberOfItems}
              slidesToScroll={3}
            >
              {galleryContent.map(({ imageUrl, imageTitle }, index) => {
                const imageData = getImage(imageUrl);
                return (
                  <div key={imageUrl}>
                    <motion.div
                      animate={{
                        opacity: inView ? 1 : 0,
                        y: inView ? '0' : '40px',
                      }}
                      whileHover={{ scale: 1.1, opacity: 0.8 }}
                      transition={{ ease: 'easeOut', duration: 0.3 }}
                      onClick={() => {
                        setIsShowClicked(true);
                        setIndexOfImage(index);
                      }}
                      css={adventuresStyles.imageWrapper}
                    >
                      {imageData && (
                        <GatsbyImage
                          image={imageData}
                          alt={imageTitle}
                          css={adventuresStyles.image}
                        />
                      )}
                    </motion.div>
                    <p css={adventuresStyles.imageTitle}>{imageTitle}</p>
                  </div>
                );
              })}
            </SliderComponent>
          </div>
          <div css={adventuresStyles.separator}></div>
        </Grid>
      </section>
    </>
  );
};
